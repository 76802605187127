import { Component } from 'react';
import { translate } from '../../util/i18n';
import ContactSection from '../../components/contactSection';
import Section from '../../components/section';
import Container from '../../components/container';
import SiteHeader from '../../components/siteHeader';
import InstagramFeed from '../../components/instagramFeed';
import MetadataHelmet from '../../components/metadataHelmet';

export default class ContactPage extends Component {

    constructor() {
        super();
        this.state = {
            posts: [],
            loading: true
        };
    }


    async componentDidMount() {
        await fetch("https://instagram-api-cfworker.jorgeberrezuetav.workers.dev/navvi.studio")
            .then(response => {
                if (!response.ok) {
                    console.debug("Could not retrieve Instagram feed.");
                    return;
                }
                return response.json()
                    .then(allData => {
                        if (allData.data.length > 0) {
                            this.setState({ posts: allData.data, loading: false });
                        }
                    })
                    .catch(err => {
                        return;
                    })
            })
    }

    render() {
        return (
            <div>
                <MetadataHelmet pageName="contact" />
                <SiteHeader />
                <Section className="bg-shark px-5 xl:px-0">
                    <Container className="border-x-[1px] border-t-[1px] border-beige/50 rounded-t-3xl">
                        <div className="p-5 flex flex-col">
                            <div id="title" className="flex justify-center">
                                <h1 className="text-cabaret text-3xl font-bold tracking-[5px]">{translate('page.contact.header.title')}</h1>
                            </div>
                        </div>
                    </Container>
                </Section>
                <ContactSection mode="light" />
                <Section className="bg-shark text-beige">
                    <Container>
                        <div className="p-5">
                            <div className="flex flex-col items-center justify-center">
                                <h1 className='text-cabaret text-3xl font-bold tracking-[5px] pb-6 pt-1'>instagram</h1>
                                <div className="grid grid-cols-2 sm:grid-cols-3 [&>*]:aspect-square [&>*]:bg-shark-800 md:min-w-[300px] w-full md:w-[80%] lg:w-[60%] max-w-[800px] gap-5">
                                    <InstagramFeed posts={this.state.posts} loading={this.state.loading} />
                                </div>
                            </div>
                        </div>
                    </Container>
                </Section>
            </div>

        );
    }

}
